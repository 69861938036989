import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { oo } from "../element/theme";
import routes from "../navigation/routes";
import { Link as GatsbyLink } from "gatsby";

const useStyles = makeStyles({
  cardGrid: {
    padding: oo.verticalSpacing / 2 + "rem 0.5rem",
    maxWidth: oo.contentWidth + "rem",
    width: "100%",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    paddingBottom: 0,
    flexGrow: 1,
  },
  cardActions: {
    paddingLeft: "0.7rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});

const cardData = [
  {
    heading: "16bit",
    link: "https://source.unsplash.com/8Gdayy2Lhi0/300x168",
    example: routes.preview.to,
    useThis: routes.preview.to,
    comingSoon: false,
  }, {
    heading: "Horror",
    link: "https://source.unsplash.com/K9nxgkYf-RI/300x168",
    example: routes.templates.to + "horror/",
    useThis: routes.preview.to,
    comingSoon: true,
  }, {
    heading: "Strategy",
    link: "https://source.unsplash.com/lOcP_QZzitI/300x168",
    example: routes.templates.to + "strategy/",
    useThis: routes.preview.to,
    comingSoon: true,
  }, {
    heading: "Sakura",
    link: "https://source.unsplash.com/McsNra2VRQQ/300x168",
    example: routes.templates.to + "sakura/",
    useThis: routes.preview.to,
    comingSoon: true,
  },
];

const TemplateGrid = () => {
  const classes = useStyles();
  return (
    <div className={classes.cardGrid}>
      <Grid container spacing={4} justify="center">
        {cardData.map(el => {
          let cardActions;
          if (el.comingSoon) {
            cardActions = (<CardActions className={classes.cardActions}>
                <Button disabled variant="text"
                        size="small" color="primary" className={classes.button}>
                  Coming Soon
                </Button>
              </CardActions>
            );
          } else {
            cardActions = (
              <CardActions className={classes.cardActions}>
                <Button component={GatsbyLink} to={el.example}
                        size="small" color="primary" className={classes.button}>
                  View Example
                </Button>
              {/*  <Divider orientation="vertical" flexItem />*/}
              {/*  <Button component={GatsbyLink} to={el.useThis}*/}
              {/*          size="small" color="primary" className={classes.button}>*/}
              {/*    Use this Template*/}
              {/*  </Button>*/}
              </CardActions>
            );
          }

          return (
            <Grid item key={el.heading} xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardMedia className={classes.cardMedia}
                           image={el.link}
                           title={el.heading} />
                <CardContent className={classes.cardContent}>
                  <Typography component="h3" variant="h5">
                    {el.heading}
                  </Typography>
                </CardContent>
                {cardActions}
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
export default TemplateGrid;