import React from "react";
import Layout from "../../components/element/layout/Layout";
import SEO from "../../components/element/SEO";
import LayoutMain from "../../components/element/layout/LayoutMain";
import PageBanner from "../../components/element/page-element/PageBanner";
import TemplateGrid from "../../components/templates/TemplateGrid";


const seo = {
  title: "Oberion",
  description: null,
  canonical: null,
};
const txt = [
  "Pick your starting point",
  "Choose a template. Making a beautiful website has never been faster.",
];

const TemplateGridIndex = () => {

  return (
    <Layout showNewsletter>
      <SEO {...seo} />
      <PageBanner heading={txt[0]} subheading={txt[1]} />
      <LayoutMain>

        <TemplateGrid />

      </LayoutMain>
    </Layout>
  );
};
export default TemplateGridIndex;